<template>
  <tr :class="{ 'is-busy': isDeleting }">
    <td @click="viewProgram" class="align-middle" role="button">{{ program.name }}</td>
    <td @click="viewProgram" class="align-middle text-muted small" role="button">
      {{ program.lastUpdatedAt | utcDateRelative }}
    </td>
    <td @click="viewProgram" class="align-middle text-muted small" role="button">
      {{ program.createdAt | utcDateReadableShort }}
    </td>
    <td class="align-middle text-end">
      <div class="d-flex justify-content-end">
        <IconButton
          @click="showAssignClientsModal = true"
          :icon="'calendar-week'"
          :text="'Assign'"
          class="btn btn-sm btn-primary me-2"
        ></IconButton>
        <IconButton
          @click="showConfirmModal = true"
          :icon="'trash-alt'"
          :isLoading="isDeleting"
          :disabled="isDeleting"
          class="btn btn-sm btn-danger"
        ></IconButton>
      </div>
    </td>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteProgram"
          @close="showConfirmModal = false"
          v-model="showConfirmModal"
          v-if="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ProgramAssignClientsModal
          @close="showAssignClientsModal = false"
          v-model="showAssignClientsModal"
          v-if="showAssignClientsModal"
          :program="program"
        ></ProgramAssignClientsModal>
      </transition>
    </portal>
  </tr>
</template>

<script>
import { httpDelete } from '@/core/http';

export default {
  name: 'ProgramListItem',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    ProgramAssignClientsModal: () => import('@/components/modal/ProgramAssignClientsModal'),
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    async deleteProgram() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const { programId } = this.program;
        await httpDelete(`/program/${programId}`);
        this.$emit('programListItemDelete', programId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
    viewProgram() {
      this.$router.push(`/program/${this.program.programId}`);
    },
  },
  data() {
    return {
      isDeleting: false,
      showConfirmModal: false,
      showAssignClientsModal: false,
    };
  },
};
</script>
